import React from "react"
import PropTypes from "prop-types"

/*
I didn't like how convoluted and full of inline styles gatsby-image is
so I created a simple version, use this in conjunction with graphQL
and `fluidImageWithDefault` inside helpers.js

Example:
import { fluidImageWithDefault } from 'utils/helpers.js'
import Image from 'components/image/image.js'

const images = useStaticQuery(graphql`
  query {
    myImage: file(relativePath: { eq: "image-path/image.png" }) {
      childImageSharp {
        fixed(width: 230) {
          ...fluidImageWithDefault
        }
      }
    }
  }
`)

<Image {...images.myImage.childImageSharp.fixed} />
*/

export default function Image({
  alt,
  src,
  srcSet,
  srcWebp,
  srcSetWebp,
  width,
  height,
  className,
  wrapperClassName,
  loading,
  ...props
}) {
  return (
    <picture className={wrapperClassName} {...props}>
      {srcWebp && srcSetWebp && (
        <source srcSet={srcSetWebp} type="image/webp" />
      )}
      <img
        loading={loading}
        className={className}
        srcSet={srcSet}
        src={src}
        alt={alt}
        width={width}
        height={height}
      />
    </picture>
  )
}

Image.defaultProps = {
  alt: "",
  loading: "lazy",
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  srcWebp: PropTypes.string,
  srcSetWebp: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.oneOf(["lazy", "auto", "eager"]),
}
